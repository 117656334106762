import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Section from "../components/Section";
import { Paragraph } from "../components/styles/Text";
import { LOGO_BLUE, MOBILE_SIZE } from "../components/styles/GlobalStyle";
import Seo from "../components/Seo";

const AboutUsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 50px;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const ContactWrapper = styled.div`
  width: 30%;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;

const LinkWrapper = styled.a`
  text-decoration: none;
`;
const ParagraphWrapper = styled(Paragraph)`
  margin-bottom: 16px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-decoration: none !important;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    margin-bottom: 10px;
  }
`;
const NameWrapper = styled(Paragraph)`
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 900;
  letter-spacing: 0.1em;
  color: ${LOGO_BLUE};
`;
const DescriptionImageWrapper = styled.div`
  display: inline-block;
  width: 70%;
  background-image: url("https://archimode.co.nz/v1/public/images/website_images/archimode-map.webp");
  aspect-ratio: 16 / 9;
  background-repeat: no-repeat;
  background-size: cover;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;

const TableCellWrapper = styled.td`
  vertical-align: top;
`;

const renderSeo = () => {
  const title = "Contact";
  const description = "Find us.";
  return <Seo title={title} description={description} />;
};

const Contact = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {renderSeo()}
      <Section title="Contact Us">
        <AboutUsWrapper>
          <ContactWrapper>
            <table>
              <tbody>
                <tr>
                  <TableCellWrapper>
                    <NameWrapper>a.</NameWrapper>
                  </TableCellWrapper>
                  <TableCellWrapper>
                    <LinkWrapper
                      href="https://goo.gl/maps/J71Pnjq1fvDiontk7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ParagraphWrapper>
                        Unit 1B / 62, Paul Matthews Road,
                        <br /> Rosedale, Auckland 0632,
                        <br /> New Zealand
                      </ParagraphWrapper>
                    </LinkWrapper>
                  </TableCellWrapper>
                </tr>
                <tr>
                  <TableCellWrapper>
                    <NameWrapper>p.</NameWrapper>
                  </TableCellWrapper>
                  <TableCellWrapper>
                    <LinkWrapper href="tel:+6494765688">
                      <ParagraphWrapper>+64 9 476 5688</ParagraphWrapper>
                    </LinkWrapper>
                  </TableCellWrapper>
                </tr>
                <tr>
                  <TableCellWrapper>
                    <NameWrapper>e.</NameWrapper>
                  </TableCellWrapper>
                  <TableCellWrapper>
                    <LinkWrapper
                      href="mailto:admin@archimode.co.nz"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ParagraphWrapper>admin@archimode.co.nz</ParagraphWrapper>
                    </LinkWrapper>
                  </TableCellWrapper>
                </tr>
              </tbody>
            </table>
          </ContactWrapper>
          <DescriptionImageWrapper
            onClick={() => {
              window.open("https://goo.gl/maps/J71Pnjq1fvDiontk7", "_blank");
            }}
          />
        </AboutUsWrapper>
      </Section>
    </>
  );
};

Contact.propTypes = {};

export default Contact;
