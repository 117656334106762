import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import ProjectGrid from "../components/image/ProjectGrid";
import Loading from "../components/Loading";
import Seo from "../components/Seo";

const ProjectCategory = ({ projects }) => {
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState(() => []);
  const { type } = useParams();

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
  }, [type]);

  useEffect(() => {
    if (projects.length === 0) {
      return;
    }
    const categoryType = type.replace(/-/g, "");
    setLoading(false);
    if (categoryType === "all") {
      return setCategoryList(
        projects.filter((project) => !project.types.includes("visualisation"))
      );
    }
    if (categoryType === "house") {
      let houseProjects = projects.filter((project) =>
        project.types.includes("house")
      );

      houseProjects = [
        houseProjects[2],
        houseProjects[0],
        houseProjects[4],
        houseProjects[5],
        houseProjects[3],
        houseProjects[1],
        houseProjects[6],
      ];
      return setCategoryList(houseProjects);
    }
    setCategoryList(
      projects.filter((project) => project.types.includes(categoryType))
    );
  }, [projects, type]);

  const renderSeo = () => {
    const title = type
      .split("-")
      .map((word) =>
        word.charAt(0).toUpperCase().concat(word.slice(1).toLowerCase())
      );
    const description = "";
    return <Seo title={title} description={description} />;
  };

  return (
    <>
      {renderSeo()}
      {loading ? <Loading /> : <ProjectGrid projects={categoryList} />}
    </>
  );
};

ProjectCategory.propTypes = {};

export default ProjectCategory;
