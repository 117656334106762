import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const GridContainer = styled.div`
  display: grid;
`;

const Grid = ({ children, ...props }) => {
  if (!Array.isArray(children)) {
    return <></>;
  }
  return <GridContainer {...props}>{children}</GridContainer>;
};

Grid.propTypes = {};

export default Grid;
