import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Carousel from "../components/image/Carousel";
import CategoryImage from "../components/image/CategoryImage";
import Grid from "../components/Grid";
import Seo from "../components/Seo";

const CATEGORIES = [
  {
    name: "Terrace Housing",
    url: "https://archimode.co.nz/v1/public/images/category_images/terrance-housing.webp",
  },
  {
    name: "House",
    url: "https://archimode.co.nz/v1/public/images/category_images/house.webp",
  },
  {
    name: "Commercial",
    url: "https://archimode.co.nz/v1/public/images/category_images/commercial.webp",
  },
  {
    name: "Visualisation",
    url: "https://archimode.co.nz/v1/public/images/category_images/visualisation.webp",
  },
];

const GridWrapper = styled(Grid)`
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
`;

const SectionWrapper = styled.div`
  margin-bottom: 16px;
`;

const ImageFrame = styled(CategoryImage)`
  flex: 49%;
`;

const renderSeo = () => {
  const title = "Home";
  const description = `
    Archimode is a global family of design specialists committed
    to delivering smart solutions for the built environment. Having studios
    in Auckland, New Zealand; Shanghai, China & Henan, China.
  `;
  return <Seo title={title} description={description} />;
};

const Home = ({ projects }) => {
  const [slides, setSlides] = useState(() => []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (projects) {
      setSlides(
        projects
          .filter((project) => !project.types.includes("visualisation"))
          .map((project) => {
            return {
              id: project.id,
              title: project.title,
              location: project.location,
              url: `/projects/${project.id}`,
              imageUrl: `https://archimode.co.nz/v1/${project.projectImages?.[0]}`,
            };
          })
      );
    }
  }, [projects]);

  return (
    <>
      {renderSeo()}
      <SectionWrapper>
        <Carousel slides={slides} />
      </SectionWrapper>
      <SectionWrapper>
        <GridWrapper numOfColumns={[2, 2, 1]}>
          {CATEGORIES.map((category, idx) => (
            <ImageFrame
              key={idx}
              name={category.name}
              ratio={"60%"}
              url={category.url}
            />
          ))}
        </GridWrapper>
      </SectionWrapper>
    </>
  );
};

Home.propTypes = {};

export default Home;
