import React from "react";
import { string, array } from "prop-types";
import { Helmet } from "react-helmet";

const SITE_TITLE = "Archimode New Zealand";

const Seo = ({ title, description, keywords, url, imageUrl }) => {
  const combinedTitle = title && title.concat(" - ").concat(SITE_TITLE);
  const keywordString = keywords && keywords.join(", ");

  return (
    <Helmet>
      {combinedTitle && <title>{combinedTitle}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywordString} />}
      {url && imageUrl && (
        <>
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:type" content="image/png" />
          <meta property="twitter:card" content={""} />
          <meta name="twitter:site" content={""} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={imageUrl} />
        </>
      )}
    </Helmet>
  );
};

Seo.propTypes = {
  title: string,
  description: string,
  keywords: array,
  url: string,
  imageUrl: string,
};
Seo.defaultProps = {
  // title: "Archimode",
  // description: `Archimode is a global family of design specialists committed
  //   to delivering smart solutions for the built environment. Having studios
  //   in Auckland, New Zealand; Shanghai, China & Henan, China.`,
  // url: "https://www.archimode.co.nz",
  // imageUrl: "",
};

export default Seo;
