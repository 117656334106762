import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { UppercaseText } from "../styles/Text";

const LinkWrapper = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.2em;
  }
`;

const ButtonTextWrapper = styled(UppercaseText)`
  font-weight: 900;

  ${(props) =>
    props.before &&
    `
  &::before {
    content: "←";
    font-size: 15px;
    margin-right: 0.3em;
  }
  `}
  ${(props) =>
    props.after &&
    `
  &::after {
    content: "→";
    font-size: 15px;
    margin-left: 0.3em;
  }
  `}
`;

const LinkButton = ({
  children,
  to,
  showLeftArrow,
  showRightArrow,
  ...props
}) => {
  return (
    <LinkWrapper to={to} {...props}>
      <ButtonTextWrapper
        before={showLeftArrow}
        after={showRightArrow}
        text={children}
      />
    </LinkWrapper>
  );
};

LinkButton.propTypes = {};

export default LinkButton;
