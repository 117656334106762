import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { UppercaseText } from "../styles/Text";
import { MOBILE_SIZE } from "../styles/GlobalStyle";

const ImageContainer = styled.div`
  position: relative;
  // width: 100%;
  aspect-ratio: 7 / 10;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
`;
const InfoContainer = styled.div`
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  padding: 5%;
  padding-top: 40px;
  text-align: right;
  color: white;
  font-size: 11px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
`;
const UppercaseTextWrapper = styled(UppercaseText)`
  display: block;
  font-size: 11px;
  color: white;
  line-height: 12px;
  letter-spacing: 0.1em;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    font-size: 8px;
  }
`;

const ProjectThumbnail = ({ project }) => {
  const { title, location, profileImage } = project;
  const thumbnailUrl = `https://archimode.co.nz/v1/${profileImage}`;

  return (
    <Link to={`/projects/${project?.id}`}>
      <ImageContainer url={thumbnailUrl} onClick={() => {}}>
        <InfoContainer>
          <UppercaseTextWrapper text={title} />
          <UppercaseTextWrapper text={location} />
        </InfoContainer>
      </ImageContainer>
    </Link>
  );
};

ProjectThumbnail.propTypes = {};

export default ProjectThumbnail;
