import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Grid from "../Grid";
import ProjectThumbnail from "./ProjectThumbnail";
import { MOBILE_SIZE } from "../styles/GlobalStyle";

const GridWrapper = styled(Grid)`
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ProjectGrid = ({ projects }) => {
  return (
    <GridWrapper>
      {projects.map((project) => (
        <ProjectThumbnail key={project.id} project={project} />
      ))}
    </GridWrapper>
  );
};

ProjectGrid.propTypes = {};

export default ProjectGrid;
