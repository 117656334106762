import React, { useState, useEffect, useRef } from "react";
import { array } from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { UppercaseText, LinkText } from "../styles/Text";
import { MOBILE_SIZE, TABLET_SIZE } from "../styles/GlobalStyle";
import { click } from "@testing-library/user-event/dist/click";

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
`;
const Slide = styled.div`
  width: 100%;
  height: 100%;
  ${(props) => (!props.isShown ? "display: none;" : "")}
`;
const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Caption = styled(Link)`
  position: absolute;
  right: 4%;
  bottom: 6%;
  text-align: right;
  text-decoration: none;
  z-index: 100;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const SlideProjectTitle = styled(LinkText)`
  display: block;
  color: white;
  font-size: 15px;
  font-weight: 700;
  text-decoration: inherit;
  text-underline-offset: 0.3em;

  @media screen and (max-width: ${TABLET_SIZE}px) {
    font-size: 11px;
  }
`;
const SlideProjectLocation = styled(LinkText)`
  display: block;
  color: white;
  font-size: 14px;
  text-decoration: inherit;

  @media screen and (max-width: ${TABLET_SIZE}px) {
    font-size: 10px;
  }
`;
const IndictorContainer = styled.div`
  display: flex;
  position: absolute;
  right: 4%;
  bottom: 4%;
  z-index: 100;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    display: none;
  }
`;
const Indictor = styled.div`
  width: 15px;
  height: 3px;
  margin: 0 3px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? "white" : "rgb(255, 255, 255, 0.5)"};
`;
const NavigationBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  ${(props) => (props.side === "left" ? "left: 0;" : "right: 0;")}
  width: 10%;
  height: 100%;
`;
const NavigationKey = styled(UppercaseText)`
  padding: 50px 0;
  width: 100%;
  text-align: center;
  color: rgba(230, 230, 230, 1);
  cursor: pointer;

  &:hover {
    color: white;
  }
`;

const Carousel = ({ slides }) => {
  const clickedRef = useRef(false);
  const autoRunSetRef = useRef(false);
  const [slideNumToShow, setSlideNumToShow] = useState(0);

  useEffect(() => {
    if (slides) {
      const lonelyTrackHouseIndex = slides.findIndex(
        (slide) => slide.id === 17
      );
      const mtEdenDevelopmentIndex = slides.findIndex(
        (slide) => slide.id === 13
      );

      const lonelyTrackHouseSlide = {
        ...slides[lonelyTrackHouseIndex],
        imageUrl:
          "https://archimode.co.nz/v1/public/images/lonely-track-house-3.webp",
      };
      const mtEdenDevelopmentSlide = {
        ...slides[mtEdenDevelopmentIndex],
        imageUrl:
          "https://archimode.co.nz/v1/public/images/mt-eden-road-development-4.webp",
      };

      slides[lonelyTrackHouseIndex] = lonelyTrackHouseSlide;
      slides[mtEdenDevelopmentIndex] = mtEdenDevelopmentSlide;

      // setSlideModified(slides)
    }
  }, [slides]);

  useEffect(() => {
    if (slides && slides.length > 0 && !autoRunSetRef.current) {
      // Only set once
      autoRunSetRef.current = true;
      setInterval(() => {
        if (clickedRef.current === false) {
          return setSlideNumToShow((prev) =>
            prev === slides.length - 1 ? 0 : prev + 1
          );
        }
        // If navigation button clicked, skip to next round.
        clickedRef.current = false;
      }, 4000);
    }
  }, [slides]);

  const checkSelected = (index) => {
    return slideNumToShow === index;
  };

  const handleClickNavButton = (step) => () => {
    clickedRef.current = true;
    setSlideNumToShow((prev) => {
      if (step > 0 && prev === slides.length - 1) {
        return 0;
      }
      if (step < 0 && prev === 0) {
        return slides.length - 1;
      }
      return prev + step;
    });
  };
  const handleClickIndicator = (index) => () => {
    clickedRef.current = true;
    setSlideNumToShow(index);
  };

  return (
    <CarouselContainer>
      {slides.map((slide, index) => {
        return (
          <Slide key={slide.id} isShown={checkSelected(index)}>
            <SlideImage src={slide.imageUrl} alt={slide.title} />
            <Caption to={slide.url}>
              <SlideProjectTitle text={slide.title} />
              <SlideProjectLocation text={slide.location} />
            </Caption>
          </Slide>
        );
      })}
      <IndictorContainer>
        {slides.map((_, index) => (
          <Indictor
            key={index}
            isSelected={checkSelected(index)}
            onClick={handleClickIndicator(index)}
          />
        ))}
      </IndictorContainer>
      <NavigationBar side="left">
        <NavigationKey text="❮" size={40} onClick={handleClickNavButton(-1)} />
      </NavigationBar>
      <NavigationBar side="right">
        <NavigationKey text="❯" size={40} onClick={handleClickNavButton(1)} />
      </NavigationBar>
    </CarouselContainer>
  );
};

Carousel.propTypes = {
  slides: array,
};
Carousel.defaultProps = {
  slides: [],
};

export default Carousel;
