import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Paragraph, UppercaseText } from "./styles/Text";

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
`;
const NameWrapper = styled(UppercaseText)`
  display: block;
  margin: 8px 0 16px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.1em;
`;
const ParagraphWrapper = styled(Paragraph)`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.1em;
`;
const BioWrapper = styled(ParagraphWrapper)`
  margin: 16px 0 8px;
`;

const PersonalInfo = ({ info }) => {
  return (
    <>
      <NameWrapper text={info?.fullName} />
      <ParagraphWrapper>{info?.title}</ParagraphWrapper>
      <ParagraphWrapper>{info?.education}</ParagraphWrapper>
      <BioWrapper>{info?.bio}</BioWrapper>
    </>
  );
};

const Card = ({ info }) => {
  return (
    <CardContainer>
      {info?.imageUrl && <ImageContainer url={info?.imageUrl} />}
      <PersonalInfo info={info} />
    </CardContainer>
  );
};

Card.propTypes = {};

export default Card;
