import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { string } from "prop-types";

import {
  APP_MAX_WIDTH,
  FOOTER_HEIGHT,
  APP_PADDING,
  MOBILE_SIZE,
} from "../styles/GlobalStyle";
import { LinkText, Paragraph } from "../styles/Text";

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  max-width: ${APP_MAX_WIDTH}px;
  padding: 0 ${APP_PADDING}px;
  height: ${FOOTER_HEIGHT}px;
  margin: 0 auto;
  font-size: 11px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  z-index: -100;
  border-top: 2px solid #dedede;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    justify-content: flex-start;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    align-items: flex-start;
  }
`;

const LinkWrapper = styled.a`
  line-height: 1.3em;
  text-decoration: none;
  cursor: pointer;
`;

const SocialLinkWrapper = styled.a`
  display: inline-block;
  text-decoration: none;
`;

const SOCIAL_MEDIAS = [];

const PHONE = "+64 9 476 5688";
const EMAIL = "admin@archimode.co.nz";
const ADDRESS =
  "Unit 1B/62 Paul Matthews Road, Rosedale, Auckland, New Zealand";

const Contacts = ({ phone, email, address }) => (
  <SectionWrapper>
    <Paragraph>
      <LinkWrapper href="tel:+6494765688">{phone}</LinkWrapper> /{" "}
      <LinkWrapper
        href="mailto:admin@archimode.co.nz"
        target="_blank"
        rel="noopener noreferrer"
      >
        {email}
      </LinkWrapper>
    </Paragraph>
    <LinkWrapper
      href="https://goo.gl/maps/J71Pnjq1fvDiontk7"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Paragraph>{address}</Paragraph>
    </LinkWrapper>
  </SectionWrapper>
);

const SocialLinks = ({ socialLinks }) => (
  <SectionWrapper>
    {socialLinks.map((ele, idx) => (
      <Fragment key={idx}>
        <SocialLinkWrapper
          to={{ pathname: ele.to }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkText text={ele.name} />
        </SocialLinkWrapper>
      </Fragment>
    ))}
  </SectionWrapper>
);

const Footer = ({ width, height }) => {
  return (
    <FooterWrapper width={width} height={height}>
      <Contacts phone={PHONE} email={EMAIL} address={ADDRESS} />
      <SocialLinks socialLinks={SOCIAL_MEDIAS} />
    </FooterWrapper>
  );
};

Footer.propTypes = {
  width: string,
  height: string,
};
Footer.defaultProps = {
  width: "100%",
  height: "10%",
};

export default Footer;
