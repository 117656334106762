import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { string } from "prop-types";

import { LinkText } from "../styles/Text";
import {
  TEXT_COLOR,
  NAVBAR_HEIGHT,
  APP_MAX_WIDTH,
  BACKGROUND_COLOR,
  APP_PADDING,
  TABLET_SIZE,
  MOBILE_SIZE,
} from "../styles/GlobalStyle";

const LINK_NAMES = [
  {
    name: "all",
    to: "/categories/all",
  },
  {
    name: "terrace housing",
    to: "/categories/terrace-housing",
  },
  {
    name: "house",
    to: "/categories/house",
  },
  {
    name: "commercial",
    to: "/categories/commercial",
  },
  {
    name: "visualisation",
    to: "/categories/visualisation",
  },
  {
    name: "about",
    to: "/about",
  },
  {
    name: "contact us",
    to: "/contact",
  },
];

const NavbarWrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: ${APP_MAX_WIDTH}px;
  padding: 0 ${APP_PADDING}px;
  height: ${NAVBAR_HEIGHT}px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background-color: ${BACKGROUND_COLOR};
  font-size: 11px;

  @media screen and (max-width: ${TABLET_SIZE}px) {
    font-size: 10px;
  }
  @media screen and (max-width: ${MOBILE_SIZE}px) {
    align-items: center;
    padding: 0 ${APP_PADDING}px;
    gap: 20%;
  }
`;
const NavArrowButtonWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
  }
`;
const NavArrowFontWrapper = styled.a`
  &::before {
    content: "⌄";
    font-size: 30px;
    line-height: 5px;
  }
`;
const NavMenuWrapper = styled.div`
  display: flex;
  gap: 18px;

  @media screen and (max-width: ${TABLET_SIZE}px) {
    width: 400px;
    gap: 14px;
    justify-content: flex-end;
  }

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    ${(props) => props.show || "display: none;"}

    position: fixed;
    top: calc(${NAVBAR_HEIGHT}px - 20px);
    left: ${APP_PADDING};
    width: calc(100% - ${2 * APP_PADDING}px);
    padding: 20px;
    background-color: rgb(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
const NavLinkWrapper = styled(NavLink)`
  color: ${TEXT_COLOR};
  text-decoration: none;
  text-underline-offset: 0.3em;
  &.active {
    text-decoration: underline;
  }

  @media screen and (max-width: ${TABLET_SIZE}px) {
    inline-size: min-content;
  }

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    color: white;
    inline-size: auto;
  }
`;
const NavLogoWrapper = styled.img`
  height: 32px;

  @media screen and (max-width: ${TABLET_SIZE}px) {
    height: 24px;
  }

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    margin-right: auto;
    height: 20px;
  }
`;

const NavLogo = () => (
  <NavLinkWrapper to={"/"} key={"home"}>
    <NavLogoWrapper
      src="https://archimode.co.nz/v1/public/images/website_images/archimode-logo.webp"
      alt="company logo"
    />
  </NavLinkWrapper>
);

const NavArrowDown = ({ onClick }) => (
  <NavArrowButtonWrapper onClick={onClick}>
    <NavArrowFontWrapper></NavArrowFontWrapper>
  </NavArrowButtonWrapper>
);

const NavMenu = ({ show, onClick }) => {
  return (
    <NavMenuWrapper show={show}>
      {LINK_NAMES.map((link, idx) => (
        <NavLinkWrapper to={link.to} key={idx}>
          <LinkText text={link.name} onClick={onClick} />
        </NavLinkWrapper>
      ))}
    </NavMenuWrapper>
  );
};

const Navbar = ({ width, height }) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <NavbarWrapper width={width} height={height}>
      <NavLogo />
      <NavArrowDown onClick={() => setIsShown((prev) => !prev)} />
      <NavMenu show={isShown} onClick={() => setIsShown(false)} />
    </NavbarWrapper>
  );
};
Navbar.propTypes = {
  width: string,
  height: string,
};
Navbar.defaultProps = {
  width: "100%",
  height: "10%",
};

export default Navbar;
