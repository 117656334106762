import React from "react";
import PropTypes from "prop-types";

import Grid from "../Grid";
import Card from "../Card";

const PersonGrid = ({ personInfos, ...props }) => {
  return (
    <Grid {...props}>
      {personInfos.map((info, idx) => (
        <Card key={idx} info={info} />
      ))}
    </Grid>
  );
};

PersonGrid.propTypes = {};

export default PersonGrid;
