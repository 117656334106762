import React, { Fragment } from "react";
import { array, number } from "prop-types";
import styled from "@emotion/styled";

import { UppercaseText, UnitText, Paragraph } from "../styles/Text";

const TableWrapper = styled.table`
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  height: 1px;
`;

const TableCell = styled.td`
  vertical-align: top;
  padding-right: 16px;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.1em;
`;

const TextWrapper = styled(Paragraph)`
  display: inline;
`;

const TableValueRow = styled.div`
  display: table;
  float: left;
  clear: both;
`;

const renderTableRows = (rows, size) => (
  <>
    {rows.map((row, idx) => (
      <TableRow key={idx}>
        <TableCell>
          <UppercaseText text={row.name} />
        </TableCell>
        <TableCell>
          {Array.isArray(row.value) ? (
            row.value.map((v, idx) => (
              <TableValueRow key={idx}>
                <TextWrapper>{v}</TextWrapper>
                <UnitText type={row.unit} />
              </TableValueRow>
            ))
          ) : (
            <>
              <TextWrapper>{row?.value}</TextWrapper>
              <UnitText type={row.unit} />
            </>
          )}
        </TableCell>
      </TableRow>
    ))}
  </>
);

const InfoTable = ({ rows, textSize }) => {
  return (
    <TableWrapper>
      <tbody>{renderTableRows(rows, textSize)}</tbody>
    </TableWrapper>
  );
};
InfoTable.propTypes = {
  rows: array,
  textSize: number,
};
InfoTable.defaultProps = {
  rows: [],
  textSize: 11,
};

export default InfoTable;
