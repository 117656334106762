import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import {
  APP_MAX_WIDTH,
  MOBILE_SIZE,
  NAVBAR_HEIGHT,
} from "../components/styles/GlobalStyle";
import ProjectInfo from "../components/ProjectInfo";
import { Paragraph } from "../components/styles/Text";
import LinkButton from "../components/buttons/LinkButton";
import Loading from "../components/Loading";
import Seo from "../components/Seo";

const PROJECT_INFO_WIDTH = "32%";
const PROJECT_IMAGES_WIDTH = "60%";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FixedSectionContainer = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  top: ${NAVBAR_HEIGHT}px;
  max-width: ${APP_MAX_WIDTH}px;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    position: static;
  }
`;
const SectionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ProjectInfoWrapper = styled(ProjectInfo)`
  width: ${PROJECT_INFO_WIDTH};

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;
const ProjectImageContainer = styled.div`
  width: ${PROJECT_IMAGES_WIDTH};
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;
const ProjectImage = styled.img`
  width: 100%;
`;

const DescriptionWrapper = styled(Paragraph)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-align: justify;
  display: none;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    display: block;
  }
`;
const ProjectNavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
  font-size: 11px;
  letter-spacing: 0.1em;
  padding: 2em 0 2em;
  z-index: 200;
`;
const LinkButtonWrapper = styled(LinkButton)`
  flex-grow: 1;
  &.right {
    text-align: right;
  }
`;

const ProjectNavbar = ({ prev, next }) => {
  if (!prev || !next) {
    return;
  }
  return (
    <ProjectNavbarWrapper>
      <LinkButtonWrapper
        className="left"
        to={`/projects/${prev.id}`}
        showLeftArrow
      >
        {prev.title}
      </LinkButtonWrapper>
      <LinkButtonWrapper
        className="right"
        to={`/projects/${next.id}`}
        showRightArrow
      >
        {next.title}
      </LinkButtonWrapper>
    </ProjectNavbarWrapper>
  );
};

const Project = ({ projects }) => {
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [projectDetail, setProjectDetail] = useState({});
  const [next, setNext] = useState();
  const [prev, setPrev] = useState();

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
  }, [projectId]);

  useEffect(() => {
    fetch(`https://archimode.co.nz/v1/projects/${projectId}`)
      .then((response) => response.json())
      .then((projectDetail) => {
        // data mapping
        const info = {
          id: projectDetail?.id,
          title: projectDetail?.title,
          subTitle: projectDetail?.location,
          tableRows: [
            ...(projectDetail?.completed
              ? [
                  {
                    name: "completion",
                    value: projectDetail.completed,
                    type: "text",
                  },
                ]
              : []),
            ...(projectDetail?.siteArea
              ? [
                  {
                    name: "site area",
                    value: projectDetail.siteArea,
                    type: "value",
                    unit: "SQUARE_METER",
                  },
                ]
              : []),
            ...(projectDetail?.grossFloorArea
              ? [
                  {
                    name: "gross floor area",
                    value: projectDetail.grossFloorArea,
                    type: "value",
                    unit: "SQUARE_METER",
                  },
                ]
              : []),
            ...(projectDetail?.designers
              ? [
                  {
                    name: "designers",
                    value: projectDetail.designers,
                    type: "text",
                  },
                ]
              : []),
            ...(projectDetail?.awards
              ? [
                  {
                    name: "awards",
                    value: projectDetail.awards,
                    type: "text",
                  },
                ]
              : []),
            ...(projectDetail?.photographers
              ? [
                  {
                    name: "photographers",
                    value: projectDetail.photographers,
                    type: "text",
                  },
                ]
              : []),
          ],
          mainDescription: projectDetail?.mainDescription,
          descriptions: projectDetail?.descriptions,
          projectImages: projectDetail?.projectImages || [],
        };
        setProjectDetail(info);
        setLoading(false);

        // find prev and next
        const currIndex = projects.findIndex(
          (project) => project.id === info.id
        );
        if (currIndex === projects.length - 1) {
          setNext(projects[0]);
          setPrev(projects[currIndex - 1]);
          return;
        }
        if (currIndex === 0) {
          setPrev(projects[projects.length - 1]);
          setNext(projects[currIndex + 1]);
          return;
        }
        setPrev(projects[currIndex - 1]);
        setNext(projects[currIndex + 1]);
      });
  }, [projectId, projects]);

  const renderSeo = () => {
    return (
      <Seo
        title={projectDetail?.title}
        description={projectDetail?.mainDescription}
      />
    );
  };

  return (
    <>
      {renderSeo()}
      {loading ? (
        <Loading />
      ) : (
        <PageContainer>
          <FixedSectionContainer>
            <ProjectInfoWrapper info={projectDetail} />
          </FixedSectionContainer>
          <SectionContainer>
            <ProjectImageContainer>
              {projectDetail?.projectImages?.map((imageUrl, idx) => (
                <ProjectImage
                  key={idx}
                  src={`https://archimode.co.nz/v1/${imageUrl}`}
                  alt={projectDetail?.title}
                />
              ))}
              {projectDetail?.descriptions?.length > 0 &&
                projectDetail?.descriptions?.map((description, index) => (
                  <DescriptionWrapper key={index}>
                    {description}
                    <br />
                  </DescriptionWrapper>
                ))}
              <ProjectNavbar prev={prev} next={next} />
            </ProjectImageContainer>
          </SectionContainer>
        </PageContainer>
      )}
    </>
  );
};
Project.propTypes = {};

export default Project;
