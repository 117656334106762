import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Paragraph, UppercaseText } from "./styles/Text";
import LinkButton from "./buttons/LinkButton";
import InfoTable from "./text/InfoTable";
import { MOBILE_SIZE } from "./styles/GlobalStyle";
import { useNavigate } from "react-router-dom";

const TitleWrapper = styled(UppercaseText)`
  display: block;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1em;
`;
const SubtitleWrapper = styled(UppercaseText)`
  display: block;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
`;
const SectionContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 2em;
  & > * {
    margin-bottom: 1em;
  }
`;
const ButtonSectionContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  z-index: 200;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    display: none;
  }
`;
const ParagraphWrapper = styled(Paragraph)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-align: justify;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    display: none;
  }
`;
const ButtonBackWrapper = styled(LinkButton)`
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.2em;
  }
`;

const ProjectInfo = ({ info, className }) => {
  const navigate = useNavigate();
  const title = info?.title;
  const subtitle = info?.subTitle;

  return (
    <div className={className}>
      <SectionContainer>
        <TitleWrapper text={title} />
        <SubtitleWrapper text={subtitle} />
      </SectionContainer>
      <SectionContainer>
        <InfoTable rows={info?.tableRows} textSize={11} />
      </SectionContainer>
      <SectionContainer>
        {info?.descriptions?.map((description, index) => (
          <ParagraphWrapper key={index}>
            {description}
            <br />
          </ParagraphWrapper>
        ))}
      </SectionContainer>
      <ButtonSectionContainer>
        <ButtonBackWrapper onClick={() => navigate(-1)} showLeftArrow>
          Back
        </ButtonBackWrapper>
      </ButtonSectionContainer>
    </div>
  );
};

ProjectInfo.propTypes = {};

export default ProjectInfo;
