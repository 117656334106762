import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { string, number } from "prop-types";

const LinkTextWrapper = styled.div`
  font-size: 1em;
  letter-spacing: 0.05em;
  color: inherit;
`;
const CapitalLetterWrapper = styled.span`
  font-size: 1.2em;
  color: inherit;
`;
const TextWrapper = styled.span`
  font-size: 1em;
  color: inherit;
`;

export const LinkText = ({ text, ...props }) => {
  if (typeof text !== "string") {
    return;
  }
  const strArray = text.trim().toUpperCase().split(/\s+/);

  return (
    <LinkTextWrapper {...props}>
      {strArray.map((str, idx) => {
        const firstChar = str.charAt(0);
        const restStr = str.substring(1);
        return (
          <Fragment key={idx}>
            <CapitalLetterWrapper>
              {idx === 0 ? firstChar : " ".concat(firstChar)}
            </CapitalLetterWrapper>
            <TextWrapper>{restStr}</TextWrapper>
          </Fragment>
        );
      })}
    </LinkTextWrapper>
  );
};
LinkText.propTypes = {
  text: string,
};
LinkText.defaultProps = {
  text: "",
};

const UppercaseTextWrapper = styled.span`
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
`;

export const UppercaseText = ({ text, ...props }) => {
  if (typeof text !== "string") {
    return <></>;
  }
  return (
    <UppercaseTextWrapper {...props}>{text.toUpperCase()}</UppercaseTextWrapper>
  );
};
UppercaseText.propTypes = {
  text: string,
};
UppercaseText.defaultProps = {
  text: "",
};

export const TitleText = ({ text, size, ...props }) => {
  return (
    <UppercaseText
      text={text}
      size={size}
      style={{ display: "block" }}
      {...props}
    />
  );
};
TitleText.propTypes = {
  text: string,
  size: number,
};
TitleText.defaultProps = {
  text: "",
  size: 20,
};

export const SubtitleText = ({ text, size, ...props }) => {
  return (
    <UppercaseText
      text={text}
      size={size}
      style={{ display: "block" }}
      {...props}
    />
  );
};
SubtitleText.propTypes = {
  text: string,
  size: number,
};
SubtitleText.defaultProps = {
  text: "",
  size: 14,
};

const ParagraphWrapper = styled.p`
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
`;
export const Paragraph = ({ children, ...props }) => {
  return <ParagraphWrapper {...props}>{children}</ParagraphWrapper>;
};
Paragraph.propTypes = {
  text: string,
};
Paragraph.defaultProps = {
  text: "",
};

export const UnitText = ({ type, ...props }) => {
  switch (type) {
    case "SQUARE_METER":
      return <TextWrapper {...props}> &#13217;</TextWrapper>;
    default:
      return <></>;
  }
};
