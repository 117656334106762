import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import PersonGrid from "../components/image/PersonGrid";
import Section from "../components/Section";
import { Paragraph } from "../components/styles/Text";
import { MOBILE_SIZE } from "../components/styles/GlobalStyle";
import Seo from "../components/Seo";

const AboutUsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 50px;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const DescriptionWrapper = styled(Paragraph)`
  font-size: 11px;
  text-align: justify;
  width: 50%;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;
const DescriptionImageWrapper = styled.div`
  display: inline-block;
  width: 70%;
  background-image: url("https://archimode.co.nz/v1/public/images/website_images/archimode-office.webp");
  aspect-ratio: 16 / 9;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;
const NzTeamGrid = styled(PersonGrid)`
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const ChinaTeamGrid = styled(PersonGrid)`
  gap: 16px;
  grid-template-columns: repeat(6, 1fr);

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 490px) {
    & span {
      width: min-content;
    }
  }
`;

const renderSeo = () => {
  const title = "About";
  const description = "";
  return <Seo title={title} description={description} />;
};

const About = (props) => {
  const [nzTeam, setNzTeam] = useState(() => []);
  const [chinaTeam, setChinaTeam] = useState(() => []);
  const [consultants, setConsultants] = useState(() => []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch("https://archimode.co.nz/v1/staff/")
      .then((response) => response.json())
      .then((data) => {
        const nz = data
          .filter(
            (member) =>
              member?.location === "New Zealand" &&
              member?.id !== 16 &&
              member?.id !== 14
          )
          .map((member) => ({
            id: member.id,
            imageUrl: `https://archimode.co.nz/v1/${member.profileImage}`,
            fullName: member.fullName,
            title: member.titles[0],
            education: member.staffDegrees[0].degree,
            bio: member.description,
          }))
          .sort((fst, sec) => fst.id - sec.id);
        const consultants = data
          .filter((member) => member?.id === 16 || member?.id === 14)
          .map((member) => ({
            fullName: member.fullName,
            education: member.staffDegrees[0].degree,
            bio: member.description,
          }));
        const china = data
          .filter((member) => member?.location === "China")
          .map((member) => ({
            fullName: member.fullName,
            title: member.titles[0],
            education: member.staffDegrees[0].degree,
          }));
        setNzTeam(nz);
        setChinaTeam(china);
        setConsultants(consultants);
      });
  }, []);

  return (
    <>
      {renderSeo()}
      <Section title="About Us">
        <AboutUsWrapper>
          <DescriptionWrapper>
            Archimode is a global family of design specialists committed to
            delivering smart solutions for the built environment. Having studios
            in Auckland, New Zealand; Shanghai, China & Henan, China, Archimode
            have professionals all across the world. We have grown rapidly, but
            we have always remained true to our business roots. Since the very
            beginning, we have retained a strong belief in the importance of
            intimacy, honesty and dedication in our work and client
            relationships.
            <br />
            <br />
            We work closely with our clients and leading experts to deliver
            better design solutions. Solutions that are relevant to the unique
            context of every project we undertake. We strive to understand our
            clients’ vision for their overall development and the long-term
            value it can provide. We partner with our clients, sharing their
            ambitions, frustrations and success.
            <br />
            <br />
            We deliver exceptional, small to large scale residential &
            commercial projects, master planning, and interiors. Our aim is to
            be at the cutting-edge of everything we do, while also delivering
            value and sustainability to all our stakeholders in each and every
            project.
          </DescriptionWrapper>
          <DescriptionImageWrapper />
        </AboutUsWrapper>
      </Section>
      <Section title="The Team">
        <NzTeamGrid personInfos={nzTeam} />
      </Section>
      <Section title="Design Consultants">
        <NzTeamGrid personInfos={consultants} />
      </Section>
      <Section title="China Branch">
        <ChinaTeamGrid personInfos={chinaTeam} />
      </Section>
    </>
  );
};

About.propTypes = {};

export default About;
