import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import { Paragraph } from "../components/styles/Text";

const NotFoundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;
const NotFoundTextWrapper = styled(Paragraph)`
  font-size: 15px;
  letter-spacing: 0.2em;
`;
const RedirectTextWrapper = styled(Paragraph)`
  font-size: 13px;
  letter-spacing: 0.2em;
`;

const NotFound = (props) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(5);

  useEffect(() => {
    if (count < 1) {
      return navigate("/");
    }
    setTimeout(() => setCount(count - 1), 1000);
  }, [count, navigate]);

  return (
    <NotFoundContainer>
      <NotFoundTextWrapper>404 Not Found</NotFoundTextWrapper>
      <RedirectTextWrapper>
        {`Redirect to Home page in ${count} seconds`}
      </RedirectTextWrapper>
    </NotFoundContainer>
  );
};

NotFound.propTypes = {};

export default NotFound;
