import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "@emotion/styled";

import {
  APP_MAX_WIDTH,
  APP_PADDING,
  NAVBAR_HEIGHT,
  FOOTER_HEIGHT,
  BACKGROUND_COLOR,
} from "./components/styles/GlobalStyle";

import Home from "./pages/Home";
import Project from "./pages/Project";
import Contact from "./pages/Contact";
import ProjectCategory from "./pages/ProjectCategory";
import About from "./pages/About";
import Navbar from "./components/navigation/Navbar";
import Footer from "./components/navigation/Footer";
import NotFound from "./pages/NotFound";
import Seo from "./components/Seo";

const PageWrapper = styled.div`
  position: relative;
  max-width: ${APP_MAX_WIDTH}px;
  padding: 0 ${APP_PADDING}px;
  margin: ${NAVBAR_HEIGHT}px auto ${FOOTER_HEIGHT + 20}px;
  z-index: 200;
  background-color: ${BACKGROUND_COLOR};
`;

const KEY_WORDS = [
  "archimode",
  "new zealand",
  "architecture",
  "architect",
  "design",
  "auckland",
  "house",
  "terrance",
  "commercial",
  "building",
  "visualisation",
];

function App() {
  const [projects, setProjects] = useState(() => []);
  useEffect(() => {
    fetch("https://archimode.co.nz/v1/projects/")
      .then((response) => response.json())
      .then((projectList) => {
        setProjects(projectList);
      });
  }, []);

  return (
    <BrowserRouter>
      <Seo keywords={KEY_WORDS} />
      <Navbar />
      <PageWrapper>
        <Routes>
          <Route exact path="/" element={<Home projects={projects} />} />
          <Route
            path="/categories/:type"
            element={<ProjectCategory projects={projects} />}
          />
          <Route
            path="/projects/:projectId"
            element={<Project projects={projects} />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </PageWrapper>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
