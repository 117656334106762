import React from "react";
import { string } from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { LinkText } from "../styles/Text";
import { MOBILE_SIZE, TABLET_SIZE } from "../styles/GlobalStyle";

const ImageContainer = styled.div`
  position: relative;
  height: auto;
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: ${(props) => props.ratio};
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${(props) => props.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1s ease;

  &:hover {
    filter: blur(1px) grayscale(100%);
  }
`;

const TitleTextWrapper = styled(LinkText)`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.3em;
  }

  @media screen and (max-width: ${TABLET_SIZE}px) {
    font-size: 18px;
  }
  @media screen and (max-width: ${MOBILE_SIZE}px) {
    font-size: 11px;
  }
`;

const CategoryImage = ({ name, ratio, url, className }) => {
  const type = name.replace(/\s/g, "-").toLowerCase();

  return (
    <ImageContainer className={className}>
      <Link to={`/categories/${type}`}>
        <ImageWrapper ratio={ratio} url={url}></ImageWrapper>
        <TitleTextWrapper text={name} />
      </Link>
    </ImageContainer>
  );
};

CategoryImage.propTypes = {
  name: string,
  ratio: string,
  url: string,
};
CategoryImage.defaultProps = {
  name: "",
  ratio: "100%",
  url: "",
};

export default CategoryImage;
