import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import { UppercaseText, Paragraph } from "./styles/Text";

const ellipsis = keyframes`
to {
  width: 1.25em;    
}
`;

const TextWrapper = styled(Paragraph)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-30px);
  font-size: 13px;
  letter-spacing: 0.1em;

  &::after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ${ellipsis} steps(4, end) 900ms infinite;
    animation: ${ellipsis} steps(4, end) 900ms infinite;
    content: "...";
    width: 0px;
  }
`;

const Loading = (props) => {
  return <TextWrapper>Archimoding</TextWrapper>;
};

Loading.propTypes = {};

export default Loading;
