// Colors
export const BACKGROUND_COLOR = "#edede9";
export const TEXT_COLOR = "#202020";
export const LOGO_BLUE = "#4C5EA5";

// Sizes
export const APP_MAX_WIDTH = 1200;
export const APP_PADDING = 20;
export const NAVBAR_HEIGHT = 90;
export const FOOTER_HEIGHT = 90;

// Sizes
export const MOBILE_SIZE = 767;
export const TABLET_SIZE = 1023;
