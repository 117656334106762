import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { TitleText } from "./styles/Text";

const TitleWrapper = styled(TitleText)`
  margin: 50px 0;
`;

const Section = ({ title, children }) => {
  return (
    <>
      <TitleWrapper text={title} />
      {children}
    </>
  );
};

Section.propTypes = {};

export default Section;
